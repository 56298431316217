@import 'variables';

.main-header-icon {
  width: 110px;
  height: 32px;
}

.menu-icon {
  width: 30px;
  height: 30px;
}

.small-icon {
  width: 24px;
  height: 24px;
}

.navbar {
  font-weight: 600;
  .nav {
    &.nav-pills {
      gap: 0.625rem;
    }
  }
}

.nav-link {
  background-color: $bg;
  &:focus {
    color: $text;
  }
  &:hover {
    background-color: $btn-hover;
    color: $text;
  }
  &:active {
    transform: scale(0.95);
  }
  &.selected {
    background-color: $btn-selected;
    color: $text;
    &:hover {
      background-color: $btn-selected-hover;
      color: $text;
    }
    &:active {
      background-color: $btn-selected-pressed;
      color: $text;
    }
  }
  --bs-nav-link-font-weight: 600;
}

.navbar-warning {
  margin-top: 50px;
}

@media (max-width: 767px) {
  .navbar-offline {
    margin-top: 50px;
  }

  .navbar-warning {
    margin-top: 80px;
  }
}

@media (max-width: 380px) {
  .navbar-warning {
    margin-top: 100px;
  }
}
