.modal-dialog-bottom {
  display: flex;
  align-items: self-end;
  min-height: calc(100% - var(--bs-modal-margin));
  padding-top: env(safe-area-inset-top);
}
@each $name, $value in $grid-breakpoints {
  @include media-breakpoint-down($name) {
    .modal-dialog-bottom-#{$name} {
      display: flex;
      align-items: self-end;
      min-height: 100%;
      padding-top: env(safe-area-inset-top);
      margin: 0;

      &.modal-dialog-scrollable {
        height: calc(100% - var(--bs-modal-margin));
      }

      .modal-content {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .modal-footer {
          padding-bottom: 0;
          padding-bottom: max(
            calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5),
            env(safe-area-inset-bottom)
          );
        }
      }
    }
  }
}
