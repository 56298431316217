@import 'variables';

.btn {
  --bs-btn-font-weight: 600;
  &:active {
    transform: scale(0.95);
  }
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.75rem;
}

.btn-primary {
  --bs-btn-bg: #{$pink};
  --bs-btn-color: #{$bg};
  --bs-btn-active-color: #{$bg};
  --bs-btn-active-bg: #{$text};
  --bs-btn-active-border-color: #{$text};
  --bs-btn-disabled-color: #{$gray-200};
  --bs-btn-disabled-bg: #{$pink} !important;

  // https://stackoverflow.com/questions/72609949/angular-event-target-blur-not-working-for-mobile
  @media (hover: hover) {
    --bs-btn-hover-bg: #{$text};
    --bs-btn-hover-border-color: #{$text};
    --bs-btn-hover-color: #{$bg};
  }
}

.btn-secondary {
  --bs-btn-bg: #{$btn};
  --bs-btn-color: #{$text};
  --bs-btn-active-color: #{$text};
  --bs-btn-active-bg: #{$btn-pressed};
  --bs-btn-active-border-color: #{$btn-pressed};
  --bs-btn-disabled-color: #{lighten($text, 10)};
  --bs-btn-disabled-bg: #{$btn} !important;

  @media (hover: hover) {
    --bs-btn-hover-bg: #{$btn-hover};
    --bs-btn-hover-border-color: #{$btn-hover};
    --bs-btn-hover-color: #{$text};
  }
}

.btn-secondary-border {
  border-color: #{$pink} !important;
  border-width: 3px !important;
  border-style: solid !important;
}

.btn-destructive {
  --bs-btn-bg: #{$btn-destructive};
  --bs-btn-color: #{$pink};
  --bs-btn-border-color: #{$btn-destructive-pressed};
  --bs-btn-active-color: #{$bg};
  --bs-btn-active-bg: #{$btn-destructive-pressed};
  --bs-btn-active-border-color: #{$btn-destructive-pressed};
  --bs-btn-disabled-color: #{$gray-200};
  --bs-btn-disabled-bg: #{$pink} !important;

  @media (hover: hover) {
    --bs-btn-hover-bg: #{$pink};
    --bs-btn-hover-border-color: #{$btn-destructive-pressed};
    --bs-btn-hover-color: #{$bg};
  }
}

.btn-navigation {
  --bs-btn-bg: #{$bg};
  --bs-btn-color: #{$text};
  --bs-btn-border-color: #{$bg};
  --bs-btn-active-color: #{$text};
  --bs-btn-active-bg: #{$btn-pressed};
  --bs-btn-active-border-color: #{$btn-pressed};
  --bs-btn-disabled-color: #{$text};
  --bs-btn-disabled-bg: #{$bg} !important;
  --bs-btn-disabled-border-color: transparent !important;

  @media (hover: hover) {
    --bs-btn-hover-bg: #{$btn-hover};
    --bs-btn-hover-border-color: #{$btn-hover};
    --bs-btn-hover-color: #{$text};
  }

  &.selected {
    --bs-btn-bg: #{$btn-selected};
    --bs-btn-color: #{$text};
    --bs-btn-border-color: #{$btn-selected};
    --bs-btn-active-color: #{$text};
    --bs-btn-active-bg: #{$btn-selected-pressed};
    --bs-btn-active-border-color: #{$btn-selected-pressed};
    --bs-btn-disabled-color: #{$gray-200};
    --bs-btn-disabled-bg: #{$pink} !important;

    @media (hover: hover) {
      --bs-btn-hover-bg: #{$btn-selected-hover};
      --bs-btn-hover-border-color: #{$btn-selected-hover};
      --bs-btn-hover-color: #{$text};
    }
  }
}

.btn-link {
  &:hover,
  &:focus-visible {
    color: var(--bs-btn-color);
    text-decoration: none;
  }
}

.btn-link-secondary {
  .btn-link {
    --bs-btn-color: #{$black};
    text-decoration-color: $black;
    &:hover,
    &:focus-visible {
      color: var(--bs-btn-color);
      text-decoration: none;
    }
  }
}

.text-deleting {
  padding: 0.75rem;
}

@media (max-width: 768px) {
  .with-left-padding-md {
    .btn-link {
      padding-left: 0px;
    }
  }
}

.observable-download-button {
  .btn-secondary {
    background-color: $gray-100;
    padding: 0px;
    border-color: #94949b;
    border-radius: 4px;
  }
}
